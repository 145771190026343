import React from "react";
import { CookieWall } from "../CookieWall";

type Props = { contact?: boolean };

export const JoinLeanyLabsForm: React.FC<Props> = ({ contact }) => {
  return (
    <div id={contact ? "contact-us" : undefined}>
      <CookieWall
        alt={null}
        altUrl="https://docs.google.com/forms/d/e/1FAIpQLSdcb4ofoXSJDiDuUeWFT3KuNMObocT7KlI-D-LfxIzfSBPh0Q/viewform"
      >
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSdcb4ofoXSJDiDuUeWFT3KuNMObocT7KlI-D-LfxIzfSBPh0Q/viewform?embedded=true"
          width="100%"
          height="1420"
          frameBorder="0"
          marginHeight={0}
          marginWidth={0}
        >
          Loading…
        </iframe>
      </CookieWall>
    </div>
  );
};
