import React from "react";
import { Technologies, Technology } from "../utils/tech";
import { Link } from "./Link";
import { useInView } from "react-intersection-observer";
import cn from "classnames";
import * as styles from "./Technologies.module.scss";

type Props = {
  tech: Technology[];
  className?: string;
};

export const TechTextList: React.FC<Props> = ({ tech, className }) => {
  // console.log("tech", tech);

  return (
    <ul className={cn(styles.list, className)}>
      {tech.map((x) => {
        const info = Technologies[x || ""];

        return <li key={x}>{info && info.name ? info.name : x}</li>;
      })}
    </ul>
  );
};

export const TechImageList: React.FC<Props> = ({ tech, className }) => {
  const [ref, inView] = useInView();

  if (!tech || !tech.length) return null;

  return (
    <ul
      ref={ref}
      className={cn(styles.imglist, "show-up", className, { visible: inView })}
    >
      {tech.map((x) => {
        const info = Technologies[x || ""];
        if (!info || !info.image) return null;
        const { url, name, image } = info;

        return (
          <li key={x}>
            <Link to={url || "#"} noIndex>
              <img title={name} alt={name} src={image} className={styles.img} />
            </Link>
          </li>
        );
      })}
    </ul>
  );
};
