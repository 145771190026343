import React from "react";
import { Layout } from "../components/Layout";
import { graphql, PageProps } from "gatsby";
import { Seo } from "../components/Seo";
import { MdxRenderer } from "../components/Mdx/MdxRenderer";
import { Vacancy } from "../components/Vacancies/Vacancy";
import { MdxImageSharp, MdxOgImage } from "../types/images";
import { Technology } from "../utils/tech";

type PageData = {
  mdx?: {
    id: string;
    excerpt: string;
    body: string;
    frontmatter: {
      title: string;
      featured: MdxImageSharp;
      ogImage: MdxOgImage;
      tech: Technology[];
      date: string;
      description: string;
      open: boolean;
    };
  };
};

export const query = graphql`
  query VacancyBySlug($slug: String!) {
    mdx(fields: { collection: { eq: "vacancies" }, slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        featured {
          ...PageFeaturedData
        }
        ogImage: featured {
          ...OgImageData
        }
        tech
        date(formatString: "MMMM DD, YYYY")
        description
        open
      }
    }
  }
`;

const VacancyPage: React.FC<PageProps<PageData>> = ({ data }) => {
  const page = data.mdx;
  if (!page || !page.frontmatter) return null;

  const { frontmatter } = page;

  return (
    <Layout noContact>
      <Seo
        noIndex
        title={frontmatter.title}
        description={frontmatter.description || page.excerpt}
        ogImage={frontmatter.ogImage}
      />
      <Vacancy
        title={frontmatter.title}
        date={frontmatter.date}
        featured={frontmatter.featured}
        tech={frontmatter.tech || undefined}
        open={frontmatter.open}
      >
        <MdxRenderer>{page.body}</MdxRenderer>
      </Vacancy>
    </Layout>
  );
};

export default VacancyPage;
