import React from "react";
import { Divider } from "../Divider";
import { Link } from "../Link";
import { PageFeatured } from "../PageFeatured";
import { TechImageList } from "../Technologies";
import { getVacanciesUrl } from "../../utils/urls";
import { JoinLeanyLabsForm } from "./JoinLeanyLabsForm";
import { Technology } from "../../utils/tech";
import { ImageType } from "../../types/images";
import { Article, ArticleContent, ArticleHeader } from "../Article";
import { Container } from "../Section";
import { Image } from "../Image";
import { PageHeader, PageTitle } from "../PageHeader";

type Props = {
  title: string;
  date: string;
  tech: Technology[];
  open?: boolean;
  featured: ImageType;
};

export const Vacancy: React.FC<Props> = ({
  title,
  date,
  tech,
  children,
  featured,
  open,
}) => {
  if (!open) {
    return (
      <>
        <Container>
          <Article>
            <PageFeatured>
              <Image image={featured} alt="Featured Image" />
            </PageFeatured>
            <ArticleHeader>
              <Link to={getVacanciesUrl()}>Back to Open Positions</Link>
              <PageTitle>Oops, it's closed</PageTitle>
            </ArticleHeader>

            <ArticleContent>
              <p>Unfortunately, we have closed this vacancy.</p>
              <p>
                Please take a look at our{" "}
                <Link to={getVacanciesUrl()}>current opportunities</Link>, and
                maybe you'll find something incredible there. Or fill out the{" "}
                <Link to="#contact-us">form below</Link>, and we'll get back to
                you quicker than the slow ones.
              </p>
            </ArticleContent>
          </Article>
          <Divider />
        </Container>
        <JoinLeanyLabsForm contact />
      </>
    );
  }

  return (
    <>
      <Container>
        <Article>
          <PageFeatured>
            <Image image={featured} alt="Featured Image" />
          </PageFeatured>
          <PageHeader>
            <Link to={getVacanciesUrl()}>Open Positions</Link>
            <PageTitle>{title}</PageTitle>
            <small>{date}</small>
          </PageHeader>

          <TechImageList tech={tech} />

          {children}
        </Article>
        <Divider />
      </Container>
      <JoinLeanyLabsForm contact />
    </>
  );
};
