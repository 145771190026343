import React from "react";
import { graphql } from "gatsby";
import * as styles from "./PageFeatured.module.scss";

type Props = {};

export const PageFeatured: React.FC<Props> = ({ children }) => {
  if (!children) return null;

  return <div className={styles.featured}>{children}</div>;
};

export const pageFeaturedDataQuery = graphql`
  fragment PageFeaturedData on File {
    childImageSharp {
      gatsbyImageData(
        width: 1200
        placeholder: TRACED_SVG
        formats: [AUTO, WEBP]
        quality: 90
      )
    }
  }
`;
